header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  padding: 0 24px;
  padding-left: 136px;
  background: rgba(18, 18, 18, 0.08);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  height: 88px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 100;
  -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0, rgba(18, 18, 18, 1) 16px, rgba(18, 18, 18, 1) 100%);
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0, rgba(18, 18, 18, 1) 16px, rgba(18, 18, 18, 1) 100%);

  .btn-content {
    min-width: 106px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  padding-left: 24px;
}

.header-right {
  display: flex;
  align-items: center;
  right: 0;
  position: absolute;
  z-index: 11111;
}

.header-gender {
  display: none;
  transition: var(--transition);

  &.visible {
    display: flex;
  }
}

.header-profile {
  position: fixed;
  z-index: 100;
  right: 24px;
  padding: 16px 24px 16px 16px;
  background: rgba(18, 18, 18, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(16px);
  border-radius: 24px;
  top: 80px;
  display: none;
  font-family: var(--font-bold);

  &.visible {
    display: block;
  }
}

.header-avatar {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 16px;

  &:hover .header-avatar-arrow {
    opacity: 1;
  }

  &.visible .header-avatar-arrow {
    opacity: 1;
    transform: rotate(180deg);
  }
}

.header-avatar-arrow {
  opacity: 0.8;
  transition: var(--transition);
  margin-left: 2px;
  min-width: 24px;
}

.header-credits {
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding-right: 1px;
  padding-left: 8px;
  font-family: var(--font-bold);
  cursor: pointer;
  .react-icon {
    height: 36px;
    width: 36px;
    min-width: 36px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    margin-left: 16px;

  }
}

.btn.header-burger {
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  display: none;
  margin-right: 24px;
  .btn-content {
    min-width: 40px;
  }

}
.header-secondary {
  display: none;
  justify-content: space-between;
  .btn {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 12px;
    padding: 0;
  }
  .react-icon {
    margin: 0;
  }
}
.header-secondary-center {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 48px;
  h2 {
    font-size: 20px;
    line-height: 24px;
    font-family: var(--font-black);
  }
}
.header-secondary-gender {
  width: 40px;
  min-width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 80px;
  position: relative;
  &.male {
    background: url("../../../../components/GenderSelect/assets/icons/male_active.svg") no-repeat center center/ 24px, #fff;
  }
  &.female {
    background: url("../../../../components/GenderSelect/assets/icons/female_active.svg") no-repeat center center/ 24px, #fff;
  }
}
.header-secondary-gender-switch {
  position: absolute;
  border: 3px solid var(--bg-main);
  width: 19px;
  height: 19px;
  background: url("../../../../assets/icons/switch.svg") no-repeat center center/ 12px,var(--bg-secondary);
  border-radius: 50%;
  bottom: -2px;
  right: -2px;
}