.gallery-page {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.gallery-list {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  width: 100%;
  margin-bottom: 24px;
  justify-content: center;
}

.gallery-item-container {
  width: 25%;
  padding: 12px;
  display: flex;
}

.gallery-item {
  border-radius: 8px;
  position: relative;
  flex: 1;
  width: 100%;
  aspect-ratio: 1/1.5;
  background: var(--bg-secondary);
  &:hover {
    &:after, .gallery-item-name {
      opacity: 1;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 0.8) 100%);
    transition: var(--transition);
    opacity: 0;
  }
}

.gallery-item-model {
  position: absolute;
  bottom: 12px;
  left: 12px;
  right: 12px;
  display: flex;
  align-items: center;
  z-index: 2;

}

.gallery-item-photo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

.gallery-item-name {
  font-family: var(--font-bold);
  margin-left: 8px;
  transition: var(--transition);
  opacity: 0;
}


.gallery-nav {
  margin-bottom: 40px;
  display: flex;
  .horizontal-scroll-container {
    min-height: 64px;
  }

}

.gallery-models-all {
  position: relative;
  .btn.active .btn-content {
    padding-right: 20px!important;
  }
  &.visible .react-icon {
    transform: rotate(180deg);
  }
}

.gallery-models-hidden {
  position: absolute;
  top: 100%;
  margin-top: 4px;
  background: rgba(18, 18, 18, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 11;
  padding: 8px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  left: 12px;
  backdrop-filter: blur(16px);
}

.gallery-models-hidden-item {
  font-size: 14px;
  transition: var(--transition);
  line-height: 20px;
  color: var(--dark-color);
  font-family: var(--font-bold);
  padding: 8px;
  cursor: pointer;

  &:hover, &.active {
    color: #fff;
  }
}

.gallery-models-item {
  margin-left: 8px;
  border-radius: 60px;
  white-space: nowrap;
  min-width: auto;
  .btn-content {
    padding: 4px;
    padding-right: 24px;
  }
  &:hover, &.active {
    color: #fff;
  }
}
