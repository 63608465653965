.auth-page {
  padding: 0 24px 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.modal-auth-google {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 24px 0;

  span {
    position: relative;
    z-index: 1;
    background: var(--bg-secondary);
    padding: 0 8px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background: var(--border-color);
  }
}

.auth-page-content {
  padding: 24px;
  background: rgba(255, 255, 255, 0.08);
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  border-radius: 32px;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}

.auth-recovery-success {
  position: relative;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  margin-top: 8px;
  overflow: hidden;
  font-size: 14px;
  line-height: 20px;
  &:after {
    content: '';
    position: absolute;
    top: -20px;
    width: 140px;
    height: 64px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 360px;
    filter: blur(60px);
    pointer-events: none;
  }
}