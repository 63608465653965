aside {
  position: fixed;
  height: 100dvh !important;
  top: 0;
  left: 0;
  padding: 24px;
  display: flex;
  z-index: 200;
  transition: var(--transition);
  user-select: none;
}

.aside {
  position: relative;
  height: 100%;
  overflow: hidden;
  width: 112px;
  background: #2B2B2B;
  flex: 1;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  transition: var(--transition);
}

.aside-logo-small {
  width: 64px;
}

.aside-logo-big {
  height: 48px;
}

.aside.big-aside {
  width: 280px;

  .aside-logo-big {
    opacity: 1;
  }

  .aside-logo-small {
    opacity: 0;
  }

  .aside-toggle-icon:after {
    left: auto;
    right: 3px;
  }

  nav .menu-item {
    span {
      opacity: 0.64;
      transform: translateX(0px);
    }

    &:hover span, &.active span {
      opacity: 1;
    }
  }

  .aside-btns span {
    opacity: 1;
    transform: translateX(0px);
  }
}

.aside-logo {
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: center;

  img {
    position: absolute;
    transition: var(--transition);
  }

  .aside-logo-big {
    opacity: 0;
  }
}

.aside-toggle {
  height: 64px;
  width: 64px;
  border-radius: 128px;
  border: 1px solid var(--border-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-self: flex-end;

  &:hover .aside-toggle-icon {
    background-color: #fff;
  }
}

.aside-toggle-icon {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  background: #C4C4C4;
  transition: var(--transition);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 3px;
    height: 12px;
    border-radius: 2px;
    background-color: #2B2B2B;
    transition: var(--transition);
    left: 3px;
  }
}

aside nav {
  margin-top: 54px;

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  .menu-item {
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-top: 8px;
    height: 64px;
    border: 1px solid var(--border-color);
    transition: var(--transition);
    padding: 0 20px;
    position: relative;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
    font-family: var(--font-bold);
    width: 100%;

    &:hover {
      -webkit-text-fill-color: #fff;
      background: transparent;
      border-color: rgba(255, 255, 255, 0.32);

      .react-icon {
        opacity: 1;
      }
    }

    &.active {
      border-color: rgba(255, 255, 255, 1);

      .react-icon {
        opacity: 1;
      }
    }
  }

  span {
    position: absolute;
    left: 60px;
    transition: var(--transition);
    opacity: 0;
    //white-space: nowrap;
    transform: translateX(-40px);
  }

  .react-icon {
    transition: var(--transition);
    opacity: 0.8;
  }
}

.aside-btns {
  padding-top: 16px;
  margin-top: 16px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 16px;
    right: 16px;
    top: 0;
    height: 1px;
    background-color: var(--divider);
    transition: var(--transition);
  }

  .btn-content {
    justify-content: flex-start;
  }

  span {
    position: absolute;
    left: 60px;
    transition: var(--transition);
    opacity: 0;
    transform: translateX(-40px);
    max-width: calc(100% - 80px);
    text-align: left;
  }
}

.aside-footer {
  background: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(16px);
  padding: 16px;
  border-radius: 24px;
  display: none;
  position: absolute;
  bottom: 8px;
  right: 8px;
  left: 8px;
}

.aside-user {
  text-decoration: none;
  background: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  padding: 4px;
  padding-right: 16px;
  width: 100%;
  display: none;
  align-items: center;
  margin-top: 16px;
  -webkit-text-fill-color: #fff;
  &:hover {
    background: rgba(255, 255, 255, 0.16);
    -webkit-text-fill-color: #fff;
  }
  &:active {
    background: rgba(255, 255, 255, 0.08);
    -webkit-text-fill-color: #fff;
  }
}
.aside-list-container {
  flex: 1;
  position: relative;
  margin-right: -24px;
}
.aside-list {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 24px;
  padding-bottom: 24px;
  &::-webkit-scrollbar {
   width: 0;
  }
  &::-webkit-scrollbar-track {
    margin-bottom: 112px;
  }
}
.aside-top {
  display: flex;
  flex-direction: column;
  flex: 1;
}