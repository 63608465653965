@media only screen and (max-width: 1300px) {
  .header {
    justify-content: flex-end;
    .h-100 {
      width: 100%;
      justify-content: space-between;
    }
  }
  .header-right {
    position: relative;
  }
}

@media only screen and (max-width: 1199px) {
  header {
    padding-left: 24px !important;
  }
  .header {
    padding-left: 0;
    justify-content: space-between;
  }
  .btn.header-burger {
    display: flex;
  }
  header {
    display: none !important;
  }
  header.header-main-mobile {
    display: flex!important;
  }
  .header-secondary {
    display: flex!important;
  }
  .header-avatar-arrow {
    display: none;
  }
}

@media only screen and (max-width: 991px) {

}

@media only screen and (max-width: 767px) {
  header {
    height: 72px;
    padding: 16px !important;
  }
  .header-avatar {
    display: none;
  }
  .header-gender {
    display: none !important;
  }
}

@media only screen and (max-width: 575px) {


}


/* touchscreens */
@media (hover: none) or (max-width: 767px) {

}

/* mouse */
@media (hover: hover) {
  /* ... */
}