@media only screen and (max-width: 1199px) {

}

@media only screen and (max-width: 991px) {
  .premium-footer {
    flex-direction: column;

    .btn {
      width: 100%;
      max-width: 500px;
    }
  }
  .premium-container {
    margin-bottom: 0;
  }
  .payment-method-page {
    width: 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .premium-page {
    padding: 0 !important;
  }
  .premium-footer {
    gap: 8px;
    margin-top: 32px;
  }
  .premium-container {
    padding: 16px;
    margin-top: 0;
    border-radius: 32px 32px 0 0;
  }
  .premium-container h6 {
    margin-bottom: 8px;

    .border-gradient-secondary {
      height: 32px;
    }
  }
  .premium-benefits {
    padding: 16px;

    .text-dark-14 {
      font-size: 12px;
    }

    .text-bold {
      font-size: 14px;
    }

    .row {
      max-height: 130px;
      overflow: auto;
      margin: 0 -16px -16px;
      padding: 0 12px 12px;

      &::-webkit-scrollbar-track {
        margin-bottom: 24px;
      }
    }
  }

  .premium-plan-item-content {
    padding-bottom: 0;
  }
  .premium-plan-item-recommended {
    padding-left: 6px;
    padding-right: 25px;
  }
  .premium-plan-item-content.credit-plan {
    padding-bottom: 4px;
  }
  .credit-plan-price-container {
    padding: 0 4px;
  }
  .credit-plan-bonus {
    left: auto;
    top: auto;
    bottom: 48px;
  }
  .credit-plan-coins {
    margin-top: 30px;
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 575px) {
  .premium-plan-item-off {
    position: absolute;
    top: 12px;
    left: 52px;
    margin-top: 0;
  }
  .premium-plan-item-recommended {
    padding-left: 12px;
    padding-right: 30px;
  }
  .premium-footer .btn {
    max-width: 100%;
  }
  .payment-platform-icons-item {
    &:first-child .payment-platform-icons-item-text {
      left: 0;
    }

    &:last-child .payment-platform-icons-item-text {
      right: 0;
    }
  }

}


/* touchscreens */
@media (hover: none) {

}

/* mouse */
@media (hover: hover) {
  /* ... */
}