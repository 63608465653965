:root {
  --bg-main: #121212;
  --bg-secondary: #262626;
  --bg-secondary-hover: #3E3A38;
  --text-color: #ffffff;
  --tint-color: #E33F71;
  --secondary-color: #FD7153;
  --muted-color: rgba(255,255,255,0.8);
  --dark-color: rgba(255,255,255,0.64);
  --divider: rgba(255,255,255,0.1);
  --border-color: rgba(255,255,255,0.1);
  --gradient: linear-gradient(to left, var(--secondary-color) 0, var(--tint-color) 100%);


  --success: #1DE23D;
  --danger: #E33F71;

  --font-regular: 'OpenSans-Regular', sans-serif;
  --font-bold: 'OpenSans-Bold', sans-serif;
  --font-semibold: 'OpenSans-SemiBold', sans-serif;

  --font-montserrat-regular: 'Montserrat-Regular', sans-serif;
  --font-montserrat-bold: 'Montserrat-Bold', sans-serif;
  --font-black: 'Montserrat-Black', sans-serif;


  --transition: all .3s ease;
  --transition-1s: all 1s ease;
}
