@media only screen and (max-width: 1199px) {
  .auth-page h4{
  display: none;
  }
}

@media only screen and (max-width: 991px) {

}

@media only screen and (max-width: 767px) {
  .auth-page {
    padding: 0!important;
  }
  .auth-page-content {
    flex: 1;
    border-radius: 32px 32px 0 0;
    padding: 16px;
  }
}

@media only screen and (max-width: 575px) {


}


/* touchscreens */
@media (hover: none) {

}

/* mouse */
@media (hover: hover) {
  /* ... */
}