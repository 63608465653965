
.model-card {
  flex: 1;
  width: 100%;
  border-radius: 32px;
  position: relative;
  overflow: hidden;
  aspect-ratio: 1/1.5;
  display: flex;
  flex-direction: column;
  &:hover {
    .model-card-about {
      transform: translateY(0);
    }
    .model-card-bg {
      height: 218px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 32px;
  }
}

.model-card.active {
  .model-card-category {
    background-color: #fff;
    background: -webkit-linear-gradient(left, var(--tint-color), var(--secondary-color));
    background: -o-linear-gradient(right, var(--tint-color), var(--secondary-color));
    background: -moz-linear-gradient(right, var(--tint-color), var(--secondary-color));
    background: linear-gradient(to right, var(--tint-color), var(--secondary-color));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.model-card-category {
  position: absolute;
  text-align: center;
  top: 12px;
  left: 12px;
  height: 40px;
  padding: 0 12px;
  border-radius: 20px;
  background: rgba(18, 18, 18, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.model-card-about {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  transition: var(--transition);
  transform: translateY(65px);
  border-radius: 32px;
}

.model-card-bg {
  position: absolute;
  left: -1px;
  height: 154px;
  right: -1px;
  bottom: -1px;
  background: linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 0.8) 100%);
  border-radius: 0 0 32px 32px;
  transition: var(--transition);
}
.model-card-chat-btn-mobile {
  display: none;
}