@media only screen and (max-width: 1800px) {
  .chats-section {
    width: 320px;
  }
}

@media only screen and (max-width: 1521px) {
  .chats-section {
    width: 280px;
  }
}

@media only screen and (max-width: 1441px) {
  .chats-section {
    width: 320px;
  }
  .chats-section.model-profile {
    display: none;
  }
  .chat-container {
    margin-right: 24px;
  }
  .chat-top-controls-profile {
    display: flex;
  }
  .chat-board-model {
    left: 16px;
  }
  .chat-container {
    margin-top: 16px;
    margin-right: 16px;
    border-radius: 16px 16px 0 0;
  }
}

@media only screen and (max-width: 1240px) {
  .chats-section {
    width: 290px;
  }
}

@media only screen and (max-width: 1199px) {
  .chats-section .header-secondary {
    display: flex !important;
  }
  .chat-container .header-secondary {
    display: none !important;
  }
}

@media only screen and (max-width: 991px) {
  .chat-board-model {
    height: 56px;
    padding: 4px 32px 4px 4px;
    font-size: 14px;
    line-height: 20px;
  }

  .chat-input {
    .form-control {
      padding-right: 84px;
      padding-left: 56px !important;

      &::placeholder {
        font-family: var(--font-regular);
      }
    }

    .btn {
      height: 48px;
      min-height: 48px;
      min-width: 48px;
      border-radius: 50%;
    }

    .avatar {
      left: 12px;
    }
  }
  .chat-control {
    padding: 0 16px 16px;
  }
  .chat-page.chat-page-type-chat {
    .chats-section {
      display: none;
    }

    .header-secondary {
      display: flex !important;
    }
  }
  .chat-page.chat-page-type-list {
    .chat-container {
      display: none;
    }
  }
  .chats-section {
    overflow: hidden;
    width: 100%;
    background: rgba(255, 255, 255, 0.08);

  }
  .chat-page {
    overflow: visible;
    margin: 0;
    background-color: transparent;
  }
  .chats-section-title {
    display: none;
  }
  .chats-side-list {
    top: 0;
    padding: 0;
    position: relative;

    .px-12 {
      padding: 0;
    }
  }
  .chats-list-item {
    padding-left: 16px;
    padding-right: 16px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;

    &.active {
      background: transparent;

      &:hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }

    &:before {
      left: 80px;
      right: 16px;
      bottom: 0;
      top: auto;
      opacity: 1 !important;
      display: block!important;
    }

  }
  .chats-list-item.active:before, .chats-list-item.active + .chats-list-item:before {
    opacity: 1;
  }
  .chat-container {
    margin: 0;
    overflow: hidden;

    .header-secondary h2 {
      font-family: var(--font-bold);
      font-size: 16px;
      line-height: 24px;
    }

    .chat-top-controls {
      display: none;
    }

    .chat-board-model {
      display: none;
    }
  }

  .chat-board {
    //position: relative!important;
    //overflow: visible;
    padding-top: 0;
    padding-bottom: 16px;
  }
  .chat-board-scroll {
    padding-top: 0;
  }
  .chat-control {
    .form-control-wrap {
      border-radius: 42px;

    }

    .form-control {
      min-height: 56px !important;
      border-radius: 42px !important;
    }

    .form-control-bg {
      border-radius: 42px !important;

      &:before {
        border-radius: 45px !important;
      }

      &:after {
        border-radius: 42px !important;
      }
    }
  }
  main.main-chat {
    overflow: hidden;
    padding-bottom: 0;

    & + footer {
      display: none;
    }
  }
  .chat-confirm-image {
    padding: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .chats-section {
    border-radius: 32px 32px 0 0;
  }
}

@media only screen and (max-width: 575px) {

  .chat-confirm-image-footer {
    flex-direction: column;
    gap: 8px;

    .btn, .w-50 {
      margin: 0 !important;
      width: 100% !important;
    }
  }
  .chat-board {
    padding-left: 16px;
    padding-right: 16px;

    .avatar {
      display: none;
    }
  }
}


/* touchscreens */
@media (hover: none) {

}

/* mouse */
@media (hover: hover) {
  /* ... */
}