@media only screen and (max-width: 1199px) {
  aside {
    height: 100dvh;
    width: 100vw;
    padding: 0;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(16px);
    opacity: 0;
    pointer-events: none;
    display: block;
    &.visible {
      opacity: 1;
      pointer-events: auto;
    }
  }
  .aside {
    flex: 0;
    width: 280px;
    border-radius: 0 32px 32px 0;
    padding: 8px;
    background-color: var(--bg-main);
    align-items: flex-start;
    height: 100%;
  }
  .aside-user {
    display: flex;
  }
  .aside-toggle {
    display: none;
  }
  .aside-logo-small {
    display: none;
  }
  .aside-logo {
    height: 48px;
    margin-bottom: 8px;
  }
  .aside-logo-big {
    display: block;
    opacity: 1 !important;
    position: relative;
  }
  .aside-top {
    width: 100%;
    padding: 24px 16px 16px;
  }
  aside nav {
    margin-top: 8px;
    span {
      position: relative;
      opacity: 1;
      transform: none;
      left: auto;
      margin-left: 16px;
    }
  }
  .aside-btns span {
    position: relative;
    opacity: 1;
    transform: none;
    left: auto;
  }
  .aside-footer {
    display: block;
  }
  .aside-list {
    padding-bottom: 112px;
  }
}

@media only screen and (max-width: 991px) {
  aside nav .menu-item {
    height: 56px;
    padding: 0 16px;
  }
  .aside-btns .btn-icon-left {
    margin-left: 0px;
  }

}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {


}


/* touchscreens */
@media (hover: none) {

}

/* mouse */
@media (hover: hover) {
  /* ... */
}