.model-profile-page {
  .slick-arrow {
    display: none!important;
  }
  .model-profile-slide img {
    border-radius:  0 0 24px 24px;
    width: 500px;
    max-width: 100%;
    object-position: top;
  }
  .btn-expand {
    display: none;
  }
}
.model-profile {
  overflow: hidden;
}
.model-profile-gallery {
  aspect-ratio: 1/1.2;
}
.modal-container .model-profile-gallery, .model-profile-page .model-profile-gallery {
  aspect-ratio: auto;
}
.model-profile-section {
  width: 356px;
  margin-right: -12px;
}


.model-profile-slide {
  position: relative;
  max-height: 412px;
  height: 412px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  width: 100%;

  img {
    height: 100%;
    object-fit: cover;
    border-radius: 24px;
  }
}


.model-profile-info {
  padding: 24px;
}

.model-profile-cards {
  margin: 8px -8px -8px;
  display: flex;
  flex-wrap: wrap;
}

.model-profile-card {
  margin: 8px;
  width: calc(50% - 16px);
  background: rgba(255, 255, 255, 0.05);
  padding: 12px 16px;
  border-radius: 16px;

}


.model-profile-head {
  position: absolute;
  z-index: 100;
  height: 72px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  .btn .react-icon {
    margin: 0!important;
  }
  .chat-top-controls {
    position: relative;
    top: 0;
    left: 0;
  }
  .chat-settings {
    background: transparent;
  }
  .chat-settings-btn {
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.16);
    &:hover {
      background: rgba(255, 255, 255, 0.24);

    }
  }
}
.model-profile .btn-expand {
  left: 24px;
}

body:has(.model-profile-page) main {
  padding-top: 0;
}