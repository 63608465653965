
.gender-select {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 32px;
  padding: 4px;
  display: flex;
  font-family: var(--font-bold);

}

.gender-select-tab {
  border-radius: 32px;
  padding: 12px 32px 12px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;


  &:hover {
    background: -webkit-linear-gradient(left, var(--tint-color), var(--secondary-color));
    background: -o-linear-gradient(right, var(--tint-color), var(--secondary-color));
    background: -moz-linear-gradient(right, var(--tint-color), var(--secondary-color));
    background: linear-gradient(to right, var(--tint-color), var(--secondary-color));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    .gender-select-icon-active {
      opacity: 1;
    }

    .gender-select-icon {
      opacity: 0;
    }
  }

  &.active {
    background: #fff;
    color: var(--bg-main);
    -webkit-text-fill-color: var(--bg-main);
  }
}

.gender-select-icon-wrap {
  position: relative;
  width: 32px;
  height: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.active {
    .gender-select-icon-active {
      opacity: 1;
    }
    .gender-select-icon {
      opacity: 0;
    }
  }
  &.xl {
    width: 64px;
    height: 64px;
    min-width: 64px;
    svg {
      width: 64px;
      height: 64px;
    }
  }
}

.gender-select-icon-active {
  position: absolute;
  opacity: 0;
}