@media only screen and (max-width: 1199px) {

}

@media only screen and (max-width: 991px) {
  .btn {
    min-height: 56px;
    min-width: 56px;
    .btn-content {
      padding: 0 16px;
      min-height: 56px;
    }
    .react-icon svg {
      width: 24px;
      height: 24px;
    }
    &.btn-xs {
      .react-icon svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  .btn-icon-left {
    margin-left: 0!important;
  }

  .btn.btn-lg {
    min-height: 64px;
    min-width: 64px;
    font-size: 16px;
    line-height: 24px;
    .btn-content {
      padding: 0 20px;
      min-height: 64px;
    }
    .react-icon svg {
      width: 24px;
      height: 24px;
    }
  }
  .btn.btn-md  {
    .btn-content {
      padding: 0 16px;
    }
  }
  .btn.btn-sm, .btn.btn-xs {
    .btn-content {
      padding: 0 12px;
    }
  }
  .btn-icon-left.btn-icon-absolute {
    left: 16px;
  }
}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {


}


/* touchscreens */
@media (hover: none) {

}

/* mouse */
@media (hover: hover) {
  /* ... */
}