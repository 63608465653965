.message {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.04);
  padding: 4px;
  font-size: 12px;
  line-height: 18px;
  min-height: 40px;

}
.message-photo {
  border-radius: 8px;
  max-width: 300px;
  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
}
.message-container {
  display: flex;
  width: 100%;
  margin-top: 8px;
  position: relative;

  &.user {
    justify-content: flex-end;

    .message {
      border-bottom-right-radius: 4px;
    }

    &.hide-avatar .message {
      border-bottom-right-radius: 12px;
    }

    .avatar {
      right: -44px;
      left: auto;
    }
  }

  &.model {
    justify-content: flex-end;
    flex-direction: row-reverse;


    .message {
      border-bottom-left-radius: 4px;
    }

    &.hide-avatar .message {
      border-bottom-left-radius: 12px;
    }

    .avatar {
      left: -44px;
    }
  }

  .avatar {
    position: absolute;
    bottom: 0;
    left: -44px;
  }
}

.chat-board .message {
  max-width: 80%;
  //max-width: calc(50% - 20px);
}


.message-typing {
  background: var(--gradient);
  width: 68px;
  height: 40px;
  border-radius: 12px 12px 12px 4px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-typing-item {
  height: 8px;
  width: 8px;
  border-radius: 16px;
  background-color: #fff;
  margin: 0 3px;
  animation: 0.75s infinite alternate typingAnimate;

  &:nth-child(2) {
    animation-delay: 0.5s;
  }

  &:nth-child(3) {
    animation-delay: 1s;
  }
}

@keyframes typingAnimate {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(0px);
  }
}
.message-uploading {
  background: var(--gradient);
  padding: 0 16px 0 12px;
  height: 40px;
  border-radius: 12px 12px 12px 4px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.message-uploading-progress {
  height: 4px;
  width: 72px;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
  background: rgba(255, 255, 255, 0.32);
  margin-left: 8px;
  &:after {
    border-radius: 4px;
    content: '';
    position: absolute;
    left: 0;
    width: 24px;
    top: 0;
    bottom: 0;
    animation: 2s infinite   uploadingAnimate;
    background-color: #fff;
  }
}

@keyframes uploadingAnimate {
  0% {
    transform: translateX(-24px);
  }
  100% {
    transform: translateX(72px);
  }
}
