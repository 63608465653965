.chat-page {
  display: flex;
  flex: 1;
  background: var(--bg-secondary);
  margin: 0 24px;
  border-radius: 32px 32px 0 0;
  overflow: hidden;
  //min-width: 2000px;
  .header-secondary {
    display: none!important;
  }
}

.chats-section {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 368px;
  border-radius: 32px 32px 0 0;
  .model-profile-slide img {
    width: 100%;
  }
}


.chats-side-list-container {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chats-side-list {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0px;
  top: 16px;
  overflow: auto;
  padding-top: 68px;

  &::-webkit-scrollbar-track {
    margin-top: 68px;
  }
}

.chats-list-item {
  padding: 12px;
  border-radius: 16px;
  transition: var(--transition);
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  &:hover {
    background: rgba(255, 255, 255, 0.05);

    &:before, & + .chats-list-item:before {
      opacity: 0;
    }
  }

  &:before {
    content: '';
    position: absolute;
    pointer-events: none;
    left: 12px;
    right: 12px;
    top: 0;
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
    transition: var(--transition);
  }

  &.active {
    background: rgba(255, 255, 255, 0.1);

    &:before, & + .chats-list-item:before {
      opacity: 0;
    }
  }

  &:first-child:before {
    display: none;
  }
}

.chats-list-item-message {
  margin-top: 2px;
  height: 20px;
  display: grid;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.64);

  img {
    width: 20px;
    height: 20px;
    object-fit: cover;
    border-radius: 2px;
  }
}

.chat-container {
  //overflow: hidden;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: url("../images/bg.png") repeat center center/115px, var(--bg-main);
  border-radius: 32px 32px 0 0;
  .header-secondary h2 {
    font-family: var(--font-bold);
  }
}

.chat-board-container {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.chat-board {
  position: absolute !important;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100%;
  overflow-y: auto !important;
  padding: 96px 76px 24px;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar-track {
    margin-top: 40px;
    margin-bottom: 24px;
  }
}

.chat-board-scroll {

}

.chat-control {
  padding: 0 24px 24px;
  position: relative;
  width: 100%;
  //overflow: hidden;
}

.chat-control.confirm-visible {
  .chat-input {
    top: (calc(100% + 125px))
    //transform: translateY(300px);
  }

  .chat-confirm-image {
    transform: translateY(0px);
  }
}

.chat-input {
  position: relative;
  width: 100%;
  transition: var(--transition);
  transform: translateY(0);

  .form-control {
    padding-right: 84px;
    padding-left: 68px !important;
    font-family: var(--font-regular);
    &::placeholder {
      font-family: var(--font-regular);
    }
  }

  .btn {
    position: absolute;
    right: 4px;
    bottom: 4px;
    z-index: 1;
    border-radius: 20px;
  }

  .avatar {
    position: absolute;
    left: 20px;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.16);
    z-index: 1;
  }
}

.chat-board-model {
  z-index: 1;
  position: absolute;
  background: var(--bg-secondary);
  display: flex;
  align-items: center;
  padding: 8px 32px 8px 8px;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  border-radius: 32px;
  top: 16px;
}


.chats-section-title {
  pointer-events: none;
  position: absolute;
  top: 0;
  text-align: center;
  left: 0;
  right: 0;
  z-index: 2;
  padding-top: 24px;
  padding-bottom: 24px;
  border-radius: 0 0 32px 32px;
  span {
    position: relative;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    background: linear-gradient(to top, transparent 24px, var(--bg-secondary) 56px, var(--bg-secondary) 100%);
  }
}

.chat-top-controls {
  position: absolute;
  top: 28px;
  right: 24px;
  z-index: 2;
  display: flex;
  align-items: center;
}

.chat-settings {
  background-color: var(--bg-secondary);
  cursor: pointer;
  border-radius: 50%;
}
.chat-top-controls-profile {
  cursor: pointer;
  display: none;
  margin-right: 16px;
  padding: 0 20px 0 8px;
  height: 40px;
  border-radius: 12px;
  background-color: var(--bg-secondary);
  transition: var(--transition);
  align-items: center;
  justify-content: center;
  &:hover {
    background: var(--bg-secondary-hover);
  }
}
.chat-settings.visible {
  .chat-settings-btn {
    background: rgba(255, 255, 255, 0.08);
  }
}

.chat-settings-btn {
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: var(--transition);
  border-radius: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: var(--bg-secondary-hover);
  }
}

.chat-confirm-image {
  z-index: 1;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
  backdrop-filter: blur(16px);
  border-radius: 24px;
  padding: 24px 16px 16px;
  transition: var(--transition);
  transform: translateY(300px);
  &:before {
    content: '';
    width: 140px;
    height: 64px;
    background: rgba(255, 255, 255, 0.5);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    top: -20px;
    z-index: -1;
    filter: blur(60px);
    pointer-events: none;
    left: 50%;
    transform: translateX(-50%);
  }
  .btn {
    white-space: pre-wrap;
    line-height: 15px;
    .btn-content {
      padding: 0 12px;
    }
  }
  .btn-secondary {
    padding-right: 32px;
  }
  //transform: translateY(80px);
}
.chat-confirm-image-footer {
  display: flex;
  margin-top: 24px;
}
.chat-control .timer-item {
  position: absolute;
  right: 4px;
  pointer-events: none;
  width: 32px !important;
  height: 32px !important;
  font-size: 14px !important;
  border-radius: 8px !important;
}
