@media only screen and (max-width: 1199px) {

}

@media only screen and (max-width: 991px) {
  .model-profile-info {
    padding: 16px;
  }
}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {

  .model-profile-page {
    .model-profile-slide {
      padding: 0;

      img {
        width: 100%;
      }
    }
  }
}


/* touchscreens */
@media (hover: none) {

}

/* mouse */
@media (hover: hover) {
  /* ... */
}