footer {
  position: fixed;
  z-index: 199;
  left: 0;
  right: 0;
  bottom: 0;
  height: 85px;
  padding-top: 16px;
  background: rgba(18, 18, 18, 0.2);
  backdrop-filter: blur(16px);
  display: none;
  nav {
    width: 100%;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-around;
    }
    .menu-item {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      opacity: 0.64;
      cursor: pointer;
      transition: var(--transition);
      width: 24px;
      height: 50px;
      &.active, &:hover {
        opacity: 1;
      }
    }
    .menu-item-text {
      position: absolute;
      top: 50px;
      font-size: 10px;
      line-height: 14px;
    }
  }
}

/* touchscreens */
@media only screen and (max-width: 1199px) {
  footer {
    display: flex;
  }
  main {
    padding-bottom: 88px;
  }
  aside {
    height: calc(100dvh - 88px);
  }
}


/* mouse */
@media (hover: hover) {
  footer {
    display: none;
  }
  main {
    padding-bottom: 0px;
  }
}