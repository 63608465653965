@media only screen and (max-width: 1199px) {

}

@media only screen and (max-width: 991px) {
  .modal-with_img-content {
    padding: 32px;
  }
  .modal-container {
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
  .modal-footer {
    gap: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-with_img-image {
    display: none;
  }
  .modal-with_img-content {
    width: 100%;
    border: none;
  }
  .generate-image-modal_models-tabs {
    .tabs {
      width: auto;
      white-space: nowrap;
      margin-right: 8px;
    }

    .horizontal-scroll {
      position: absolute;
    }
  }
}

@media only screen and (max-width: 575px) {
  .modal-gallery {
    width: 100vw;
    height: 100%;
    max-height: 100%;
    .slick-slider {
      width: 100vw;
    }
    img {
      width: auto;
      height: auto;
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
    }
    .slick-list {
      border-radius: 0;
    }
  }

}


/* touchscreens */
@media (hover: none) {

}

/* mouse */
@media (hover: hover) {
  /* ... */
}